import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles.css";
import "./fonts.css";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const container = document.getElementById("root");
if (!container) throw new Error("container not found!");

document.title = "Hail Photography";

const theme = createTheme({
  spacing: (factor: number) => `${0.3 * factor}rem`,
  typography: {
    fontFamily: "Caviar Dreams, sans-serif",
    h1: {
      fontSize: "1.5rem",
      "@media (min-width:768px)": {
        fontSize: "2rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "3rem",
      },
    },
    h2: {
      fontSize: "1rem",
      "@media (min-width:768px)": {
        fontSize: "1.6rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "2.2rem",
      },
    },
    h3: {
      fontSize: "0.8rem",
      "@media (min-width:768px)": {
        fontSize: "1.0rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "1.2rem",
      },
    },
    body1: {
      fontFamily: "Geo Sans Light, sans-serif",
      fontSize: "0.8rem",
      "@media (min-width:768px)": {
        fontSize: "1rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "1.2rem",
      },
    },
    body2: {
      fontFamily: "Geo Sans Light, sans-serif",
      fontSize: "0.6rem",
      "@media (min-width:768px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "1rem",
      },
    },
    subtitle1: {
      fontSize: "0.6rem",
      "@media (min-width:768px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width:1024px)": {
        fontSize: "1rem",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
