import { Box, Container, Link, Typography } from "@mui/material";

interface ErrorPageProps {
  errorCode: number;
}

function getBody(errorCode: number) {
  const notFoundBody =
    "You have somehow reached a page that does not exist. Please use the navigation at the top.";

  switch (errorCode) {
    case 404:
      return notFoundBody;
    default:
      return null;
  }
}

export function ErrorPage(props: ErrorPageProps) {
  const { errorCode } = props;

  const body = getBody(errorCode);

  return (
    <Container maxWidth="lg">
      <Typography variant="h1" textAlign="center" mb={1}>
        Oops!
      </Typography>
      <Typography variant="subtitle1" textAlign="center" mb={4}>
        {`Error ${errorCode}`}
      </Typography>
      <Box className="flex justify-center flex-col items-center">
        <Typography variant="body1" mb={4} className="w-2/3 text-center">
          {body}
        </Typography>
        <Box textAlign="center">
    <Link href="/">Return to home</Link>
  </Box>
      </Box>
    </Container>
  );
}
