import { getImageUrl } from "./getImageUrl";
import { Size } from "./size";

export function getImageUrls(
  portfolioId: string,
  albumId: string,
  count: number,
  size: Size = Size.Small
) {
  return Array.from({ length: count }, (_, index) =>
    getImageUrl(portfolioId, albumId, index + 1, size)
  );
}
