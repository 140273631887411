import { Box, Container, Link, Typography } from "@mui/material";

export function About() {
  return (
    <Container maxWidth="lg">
      <Typography variant="h1" textAlign="center" mb={4}>
        About
      </Typography>
      <Box className="flex justify-center">
        <Typography
          variant="body1"
          mb={4}
          className="w-11/12 md:w-3/4 lg:w-2/3"
        >
          Hello, I'm Hail
          <br />
          <br />
          I'm a photographer based in Canberra, the capital city of Australia.
          My passion lies in seizing the moments that truly resonate, capturing
          the essence of life as it unfolds. From the vibrant personalities in
          portraits to the dynamic energy of gigs and the excitement of events,
          my lens is always ready to tell stories with style. Every click is an
          opportunity to freeze time, transforming fleeting moments into
          cherished memories that will last a lifetime.
          <br />
          <br />
          With a focus on authenticity and emotion, my photography is more than
          just images—it's a journey through the moments that define us. Each
          portrait, event, and gig serves as a canvas to paint stories that
          connect, inspire, and evoke emotion. Through a blend of creativity and
          technical skill, I strive to encapsulate the essence of every moment,
          ensuring that each photograph holds a special place in the hearts of
          those who view it.
          <br />
          <br />
          Collaboration and connection are at the core of my approach to
          photography. I believe in building meaningful relationships with my
          clients, capturing their unique essence with honesty and respect.
          Together, let's embark on a journey of capturing moments, creating
          memories, and celebrating the beauty of life through the art of
          photography.
          <br />
          <br />
          Thank you for taking the time to visit my website. I look forward to
          the opportunity to work together and create unforgettable memories.
          Feel free to view my portfolio <Link href="/portfolio">here</Link>, or
          contact me <Link href="/contact">here</Link> for any enquiries or just
          to say hi!
        </Typography>
      </Box>
    </Container>
  );
}
