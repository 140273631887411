import { Extension } from "./extension";
import { Size } from "./size";
import config from "../../../../assets/config/config.json";

export function getImageUrl(
  portfolioId: string,
  albumId: string,
  index: number,
  size: Size = Size.Small,
  extension: Extension = Extension.AVIF
) {
  return `${config.cloudflareUrl}/${portfolioId}/${albumId}/${getImageName(index, size, extension)}`;
}

function getImageName(index: number, size: Size, extension: Extension) {
  return `${size}-${index.toString().padStart(2, "0")}.${extension}`;
}
