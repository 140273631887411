import { Box } from "@mui/material";
import { SocialLink } from "../SocialLink";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";

export function SocialLinks() {
  return (
    <Box className="items-center justify-center hidden col-span-1 space-x-4 mb-8 sm:flex">
      <SocialLink
        title="Instagram"
        link="https://www.instagram.com/haaail.photo/"
        icon={InstagramIcon}
      />
      <SocialLink
        title="Facebook"
        link="https://www.facebook.com/haaail.photography/"
        icon={FacebookIcon}
      />
      <SocialLink
        title="Email"
        link="mailto:hail@hailphotography.com"
        icon={EmailIcon}
      />
    </Box>
  );
}
