import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { CardContent } from "@mui/material";

interface ImageLinkProps {
  portfolioId: string;
  albumId?: string;
  title: string;
  image: string;
}

export function ImageLink(props: ImageLinkProps) {
  const { portfolioId, albumId = "", title, image } = props;

  return (
    <CardActionArea component="a" href={`/portfolio/${portfolioId}/${albumId}`}>
      <Card
        className="relative h-0 flex flex-column aspect-square"
        sx={{
          paddingTop: "100%",
        }}
      >
        <CardMedia
          component="img"
          image={image}
          alt={title}
          className="absolute top-0 left-0 w-full h-full"
        />
        <CardContent className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <Typography variant="h2" color="white">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
}
