import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";
import config from "../../../../assets/config/config.json";

interface HeaderProps {
  title: string;
}

export function NavBar(props: HeaderProps) {
  const { title } = props;
  const sections = [
    { title: "Home", url: "/" },
    { title: "About", url: "/about" },
    { title: "Portfolio", url: "/portfolio" },
    { title: "Contact", url: "/contact" },
  ];

  return (
    <React.Fragment>
      <Toolbar className="mt-4 mb-8">
        <Box className="flex-1">
          <Link key={title} href="/" className="inline-block w-fit">
            <img src={`${config.cloudflareUrl}/logo.svg`} className="w-56" />
          </Link>
        </Box>
        {sections.map((section) => (
          <Link
            key={section.title}
            color="inherit"
            variant="h3"
            href={section.url}
            underline="none"
            sx={{
              p: 1,
              flexShrink: 0,
              "&:hover": {
                color: "secondary.main",
              },
            }}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}
