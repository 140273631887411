import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Copyright } from "./components/Copyright";
import { SocialLinks } from "./components/SocialLinks";

function Location() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      Canberra, Australia
    </Typography>
  );
}

export function Footer() {
  return (
    <Box component="footer" py={6}>
      <Container maxWidth="lg">
        <SocialLinks />
        <Copyright />
        <Location />
      </Container>
    </Box>
  );
}
