import { Box, Stack } from "@mui/material";
import React from "react";
import { ImageLink } from "../../common/components/ImageLink";
import { getImageUrl } from "../../common/functions/imageGetter/getImageUrl";
import { Portfolio } from "../../common/types/portfolios";

interface PortfolioAlbumListProps {
  portfolio: Portfolio;
}

export function PortfolioAlbumList(props: PortfolioAlbumListProps) {
  const { portfolio } = props;

  return (
    <React.Fragment>
      <Box className="mx-2 md:mx-4 lg:mx-8">
        <Stack direction="row" spacing={6}>
          {portfolio.albums.map((album) => (
            <ImageLink
              portfolioId={portfolio.id}
              albumId={album.id}
              title={album.title}
              image={getImageUrl(portfolio.id, album.id, 1)}
            />
          ))}
        </Stack>
      </Box>
    </React.Fragment>
  );
}
