import { Box, ImageList, ImageListItem } from "@mui/material";
import { Album } from "../../common/types/portfolios";
import { getImageUrls } from "../../common/functions/imageGetter/getImageUrls";
import React from "react";
import { ImageModal } from "./imageModal";
import { Size } from "../../common/functions/imageGetter/size";

interface PortfolioAlbumProps {
  portfolioId: string;
  album: Album;
}

export function PortfolioAlbum(props: PortfolioAlbumProps) {
  const { portfolioId, album } = props;

  const smallImageUrls = getImageUrls(
    portfolioId,
    album.id,
    album.count,
    Size.Small
  ).reverse();
  const mediumImageUrls = getImageUrls(
    portfolioId,
    album.id,
    album.count,
    Size.Medium
  ).reverse();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalImageUrl, setModalImageUrl] = React.useState<string>("");
  const [modalImageAlt, setModalImageAlt] = React.useState<string>("");

  const handleOpen = (imageUrl: string, alt: string) => {
    setModalImageUrl(imageUrl);
    setModalImageAlt(alt);
    setIsModalOpen(true);
  };

  const handleClose = () => setIsModalOpen(false);

  const generateAlt = (index: number) =>
    `${album.id}-${(index + 1).toString().padStart(2, "0")}`;

  return (
    <Box className="md:mx-2 md:mx-4 lg:mx-8">
      <ImageList variant="masonry" cols={3} gap={8}>
        {smallImageUrls.map((imageUrl, index) => (
          <ImageListItem className="cursor-pointer">
            <img
              srcSet={`${imageUrl}`}
              alt={generateAlt(index)}
              loading="lazy"
              onClick={() =>
                handleOpen(mediumImageUrls[index], generateAlt(index))
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <ImageModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        imageUrl={modalImageUrl}
        alt={modalImageAlt}
      />
    </Box>
  );
}
