import * as React from "react";
import { ImageLink } from "../../common/components/ImageLink";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import portfolios from "../../../assets/portfolios/portfolios.json";
import { getImageUrl } from "../../common/functions/imageGetter/getImageUrl";
import { Size } from "../../common/functions/imageGetter/size";

export function Home() {
  return (
    <React.Fragment>
      <Box sx={{ maxHeight: "100vh" }}>
        <Card
          className="relative mb-12 md:mb-24 lg:mb-36 rounded-none"
          sx={{
            paddingTop: "50%",
            borderRadius: "0",
          }}
        >
          <CardMedia
            component="img"
            image={getImageUrl("misc", "2024-05", 1, Size.Medium)}
            alt="main-image"
            className="absolute top-0 left-0 w-full h-full"
          />
          <CardContent className="absolute bottom-0 left-0 w-full flex flex-col justify-center items-center">
            <Typography variant="h1" color="white" className="mb-1">
              Welcome to Hail Photography
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box className="flex justify-center mb-12 md:mb-24 lg:mb-36">
        <Typography variant="body1" className="w-3/4 md:w-1/2 lg:w-1/3">
          I'm a photographer based in Canberra, Australia. My work is all about
          snapping moments that matter. Whether it's portraits that capture
          personality, the buzz of events, or the energy of gigs, my photos tell
          stories with style. I strive to encapsulate the essence of every
          moment, ensuring that each photograph becomes a cherished memory to be
          treasured for a lifetime.
        </Typography>
      </Box>
      <Box className="flex justify-center">
        <Stack
          direction="row"
          spacing={6}
          className="w-11/12 md:w-3/4 lg:w-2/3"
        >
          {[portfolios[1], portfolios[1], portfolios[1]].map((portfolio) => (
            <ImageLink
              portfolioId={portfolio.id}
              title={portfolio.title}
              image={getImageUrl(portfolio.id, portfolio.albums[0].id, 1)}
            />
          ))}
        </Stack>
      </Box>
    </React.Fragment>
  );
}
