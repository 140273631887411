import { Box, Stack } from "@mui/material";
import React from "react";
import { ImageLink } from "../../common/components/ImageLink";
import portfolios from "../../../assets/portfolios/portfolios.json";
import { getImageUrl } from "../../common/functions/imageGetter/getImageUrl";

export function PortfolioList() {
  return (
    <React.Fragment>
      <Box className="mx-2 md:mx-4 lg:mx-8">
        <Stack direction="row" spacing={6}>
          {portfolios.map((portfolio) => (
            <ImageLink
              portfolioId={portfolio.id}
              title={portfolio.title}
              image={getImageUrl(portfolio.id, portfolio.albums[0].id, 1)}
            />
          ))}
        </Stack>
      </Box>
    </React.Fragment>
  );
}
