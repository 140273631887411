import { Link } from "@mui/material";

interface SocialLinkProps {
  title: string;
  link: string;
  icon: React.ElementType;
}

export function SocialLink(props: SocialLinkProps) {
  const { title, link, icon: SvgIcon } = props;

  return (
    <Link
      title={title}
      href={link}
      underline="none"
      target="_blank"
      rel="noopener"
    >
      <SvgIcon fontSize="small" color="action" />
    </Link>
  );
}
