import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavBar } from "./common/components/NavBar";
import { Footer } from "./common/components/Footer";
import portfolios from "../assets/portfolios/portfolios.json";
import { PortfolioAlbumList } from "./features/portfolioAlbumList";
import { About } from "./features/about";
import { Contact } from "./features/contact";
import { PortfolioList } from "./features/portfolioList";
import { ErrorPage } from "./common/components/ErrorPage";
import { Home } from "./features/home";
import { Album, Portfolio } from "./common/types/portfolios";
import { PortfolioAlbum } from "./features/portfolioAlbum";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export function App() {
  return (
    <BrowserRouter>
      <NavBar title="Hail Photography" />
      <GoogleReCaptchaProvider
        reCaptchaKey={"6LfZML0pAAAAAMmFutoGh-lEK_qaFWH-7I744JfL"}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<PortfolioList />} />
          <Route path="/contact" element={<Contact />} />
          {portfolios.map((portfolio: Portfolio) => (
            <Route
              key={portfolio.id}
              path={`/portfolio/${portfolio.id}`}
              element={<PortfolioAlbumList portfolio={portfolio} />}
            />
          ))}
          {portfolios.map((portfolio: Portfolio) =>
            portfolio.albums.map((album: Album) => (
              <Route
                key={album.id}
                path={`/portfolio/${portfolio.id}/${album.id}`}
                element={
                  <PortfolioAlbum portfolioId={portfolio.id} album={album} />
                }
              />
            ))
          )}
          <Route path="*" element={<ErrorPage errorCode={404} />} />
        </Routes>
      </GoogleReCaptchaProvider>
      <Footer />
    </BrowserRouter>
  );
}
