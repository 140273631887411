import { Typography } from "@mui/material";

export function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {" Hail Photography. All Rights Reserved"}
    </Typography>
  );
}
