import { Modal, Box } from "@mui/material";

interface ImageModalProps {
  isOpen: boolean;
  handleClose: () => void;
  imageUrl: string;
  alt: string;
}

export function ImageModal(props: ImageModalProps) {
  const { isOpen, handleClose, imageUrl, alt } = props;

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        className="absolute top-1/2 left-1/2 border-transparent shadow w-2/3 max-h-2/3 h-auto outline-none"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <img srcSet={imageUrl} alt={alt} loading="eager" />
      </Box>
    </Modal>
  );
}
