import SendIcon from "@mui/icons-material/Send";
import { useForm, ValidationError } from "@formspree/react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export function Contact() {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [state, handleSubmit] = useForm("mdoqynlk", {
    data: { "g-recaptcha-response": executeRecaptcha },
  });

  if (state.succeeded) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h1" textAlign="center" mb={8}>
          Thank you!
        </Typography>
        <Box className="flex justify-center">
          <Typography
            variant="body1"
            mb={4}
            className="w-11/12 md:w-3/4 lg:w-2/3"
          >
            I usually reply within 24 hours. If you haven't heard back please
            check your spam mail or give me a nudge!
            <br />
            <br />
            <br />
            Email:{" "}
            <Link href="mailto:hail@hailphotography.com">
              hail@hailphotography.com
            </Link>
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h1" textAlign="center" mb={4}>
        Contact
      </Typography>
      <Box className="flex justify-center">
        <Typography
          variant="body1"
          mb={4}
          className="w-11/12 md:w-3/4 lg:w-2/3"
        >
          Please use the form below for any enquires, or email me directly.
          <br />
          <br />I usually reply within 24 hours. If you haven't heard back
          please check your spam mail or give me a nudge!
          <br />
          <br />
          <br />
          Email:{" "}
          <Link href="mailto:hail@hailphotography.com">
            hail@hailphotography.com
          </Link>
        </Typography>
      </Box>
      <form target="_blank" onSubmit={handleSubmit} method="POST">
        <Stack spacing={2} className="items-center">
          <TextField
            id="name"
            label="Name"
            name="name"
            required
            variant="outlined"
            className="w-11/12 md:w-3/4 lg:w-2/3"
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <TextField
            id="email"
            label="E-mail"
            type="email"
            name="email"
            required
            variant="outlined"
            className="w-11/12 md:w-3/4 lg:w-2/3"
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <TextField
            id="message"
            label="Message"
            name="message"
            variant="outlined"
            required
            multiline
            rows={6}
            className="w-11/12 md:w-3/4 lg:w-2/3"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <Button
            type="submit"
            variant="contained"
            endIcon={<SendIcon />}
            className="w-2/3"
            disabled={state.submitting}
            sx={{
              textTransform: "none",
            }}
          >
            Send message
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
